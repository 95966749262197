@import "../../../styles/Mixins.module";
@import "../../../styles/Globals.module";

.wrapper {
  display: flex;
  flex-direction: column;
}

.kpiWrapper {
  display: flex;
  flex-direction: row;
  @include md {
    flex-direction: column;
  }
}

.kpiLine {
  display: flex;
  flex-direction: row;
}

.chartsWrapper {
  margin: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  @include md {
    flex-direction: column;
  }
}

.tablesWrapper {
  margin: 12px;
  display: flex;
  flex-direction: row;
  width: 100%;
  @include md {
    flex-direction: column;
  }
}

.chartWrapper {
  @include generalWrapper;
}

.evolutionPerformanceChart {
  margin: 8px;
  //max-height: 320px;
}

.evolutionComplianceChart {
  width: 40%;
  padding: 8px 0;
  @include md {
    width: 100%;
    height: 100%;
  }
}

.evolutionLeadTimeChart {
  width: 60%;
  padding: 8px 0;

  @include md {
    width: 100%;
    height: 100%;
  }
}

.chartTitle {
  @include wrapperTitle;
  padding: 8px;
}
