@import "../../styles/Mixins.module";
@import "../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.infoWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.statsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Dos columnas iguales */
  gap: 16px; /* Espaciado entre los elementos */
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.statItem {
  display: flex;
  justify-content: space-between; /* Espacia el label y el valor */
  align-items: center; /* Alinea verticalmente */
}

.label {
  font-weight: 500;
  color: #555;
  font-size: 14px;
}

.value {
  font-weight: 600;
  font-size: 16px;
  color: #333;
}

.clientsWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.tableWrapper {
  padding: 10px;
}

.inputNumber {
  width: 100%;
}

.headerTableWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subTitle {
  @include wrapperTitle;
  padding: 10px;
}

.clientsTitle {
  @include wrapperTitle;
}

.newForm {
  padding: 30px;
}

.title {
  @include pageTitle;
}

.contactInfoItem {
  width: 300px;
}

.actionButtonsWrapper {
  display: flex;
}

.saveButton {
  margin: 0 15px;
}

.tablesHeader {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
}

.cancelButton {
  @include cancelButton;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}
.actionsButton {
  @include greenButon;
}

.editButton {
  margin: 10px;
  @include greenButon;
}

.addButton {
  margin-top: 10px;
  width: 100%;
}

.formTitle {
  @include wrapperTitle;
}
.dateSelector {
  width: 100%;
}

@media only screen and (max-width: 812px) {
  .infoWrapper {
    margin: 10px;
  }
}
