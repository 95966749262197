@import "./Globals.module";

$xxs: 375px;
$xs: 428px;
$sm: 768px;
$md: 991px;
$lg: 1280px;
$xl: 1440px;
$xxl: 1920px;

@mixin xxs() {
  @media (max-width: $xxs) {
    @content;
  }
}

@mixin xs() {
  @media (max-width: $xs) {
    @content;
  }
}

@mixin sm() {
  @media (max-width: $sm) {
    @content;
  }
}

@mixin md() {
  @media (max-width: $md) {
    @content;
  }
}

@mixin lg() {
  @media (max-width: $lg) {
    @content;
  }
}

@mixin xl() {
  @media (max-width: $xl) {
    @content;
  }
}

@mixin xxl() {
  @media (max-width: $xxl) {
    @content;
  }
}

@mixin pageWrapper {
  background-color: $violet-color-1;
  padding: 20px;
  min-height: 100vh;
}

@mixin generalWrapper {
  background-color: white;
  -webkit-box-shadow: 0 10px 30px 0 rgb(24 28 33 / 5%);
  box-shadow: 0 10px 30px 0 rgb(24 28 33 / 5%);
  border-radius: 11px;
}
@mixin generalMargin {
  margin: 8px 32px;
  @include md {
    margin: 8px 12px;
  }
}

@mixin pageTitle {
  color: $violet-color-10;
  font-weight: 500;
  font-size: 24px;
  text-indent: -1px;
  line-height: 1;
}

@mixin wrapperTitle {
  color: $violet-color-10;
  font-weight: 800;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 1;
  padding: 20px;
  margin-bottom: 10px;
  letter-spacing: 0.2px;
}

@mixin actionButtonMore {
  border: none;
  background-color: inherit;
  background: none;
  flex-direction: column;
  display: flex;
}

@mixin actionIconMore {
  color: #666666;
  font-size: 24px;
}

@mixin generalTable {
  :global(.ant-table-column-sort) {
    background: inherit;
  }

  :global(.ant-table-thead .ant-table-cell) {
    color: $violet-color-7;
  }
  .rowLight {
    background-color: #ffffff;
  }

  .rowDark {
    background-color: $violet-color-1;
  }
}

@mixin dateSelectFilter {
  background-color: #ece5f6;
  border-radius: 10px;
  :global(.ant-picker-input > input::placeholder) {
    color: $violet-color-10;
  }
  :global(.ant-picker-suffix) {
    color: $violet-color-7;
  }
}

@mixin generalSelectFilters {
  :global(.ant-select-selector) {
    background-color: #ece5f6 !important;
    border-radius: 10px !important;
    color: $violet-color-10;
  }
  :global(.ant-select-arrow) {
    color: $violet-color-7;
  }
  :global(.ant-select-selection-placeholder) {
    color: $violet-color-10;
  }
}

@mixin inputFilter {
  background-color: #ece5f6 !important;
  border-radius: 10px !important;
  color: $violet-color-10;
}

@mixin wrapperExeption {
  display: flex;
  align-items: center;
  gap: calc((100% - 750px) / 2);
}

@mixin greenButon {
  background-color: $green-color-7;
  border-color: $green-color-7;
  &:hover {
    background-color: $green-color-6;
    border-color: $green-color-6;
  }
  &:focus {
    background-color: $green-color-6;
    border-color: $green-color-6;
  }
}

@mixin pinkButton {
  border-radius: 10px;
  background: linear-gradient(
    262.32deg,
    $pink-color-10 1.24%,
    $pink-color-1 93.71%
  );
  border-color: $pink-color-6;
  &:hover {
    background: linear-gradient(
      262.32deg,
      $pink-color-6 1.24%,
      $pink-color-3 93.71%
    );
    border-color: $pink-color-6;
  }
  &:focus {
    background: linear-gradient(
      262.32deg,
      $pink-color-6 1.24%,
      $pink-color-3 93.71%
    );
    border-color: $pink-color-6;
  }
}

@mixin cancelButton {
  background-color: #8f9cc0;
  border-color: #8f9cc0;
  &:hover {
    background-color: #000000;
    border-color: #000000;
  }
  &:focus {
    background-color: #000000;
    border-color: #000000;
  }
}
