@import "../../styles/Mixins.module";
@import "../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.newForm {
  padding: 30px;
}

.title {
  @include pageTitle;
}

.actionButtonsWrapper {
  display: flex;
}

.saveButton {
  margin: 0 15px;
}

.tablesHeader {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
}

.tableWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.newForm {
  padding: 30px;
}

.addNewUnitButton {
  margin-top: 10px;
  width: 100%;
}

.newChannelItem {
  display: inline;
}

.tableTitle {
  @include wrapperTitle;
}

.table {
  margin: 10px;
  padding: 10px;
}

.cancelButton {
  @include cancelButton;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.branchOfficeInfoWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.itemWrapper {
  display: flex;
  justify-content: space-between;
}

.inputNumber {
  width: 100%;
}

.actionsButton {
  @include greenButon;
}

.formTitle {
  @include wrapperTitle;
}

.formItem {
  display: inline;
  width: 250px;
}

.itemWrapperException {
  @include wrapperExeption;
}

.multipleSelect {
  max-height: 100px;
  overflow: scroll;
}

@media only screen and (max-width: 812px) {
  .tableWrapper {
    margin: 10px;
  }

  .table {
    overflow: auto;
    margin: 10px;
  }

  .itemWrapper {
    display: inherit;
  }
  .itemWrapperException {
    display: inherit;
  }

  .branchOfficeInfoWrapper {
    margin: 10px;
  }

  .formItem {
    margin: auto;
  }
}
