@import "../../styles/Mixins.module.scss";

.wrapper {
  @include pageWrapper;
}

.contentWrapper {
  display: flex;
  justify-content: space-around;
}

.filtersWrapper {
  display: flex;
  margin: 10px;
  gap: 4px;

  @include md {
    flex-direction: column;
  }
}

.filterWrapper {
  display: flex;
  gap: 4px;

}

.filterInput {
  @include inputFilter;
}

.filterInputNumber {
  width: 100%;
  @include inputFilter;
}

.actionButtonsWrapper {
  display: flex;
}

.importButton {
  @include greenButon;
}

.newButton {
  @include greenButon;
  margin: 0 10px;
}

.tableWrapper {
  @include generalWrapper;
  @include generalMargin;
  height: 100%;
  width: 100%;
}

.newWrapper {
  @include generalWrapper;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 500px;
}

.table {
  padding: 10px;
}

.formNew {
  padding: 10px;
  max-width: 400px;
}

.formItemsWrapper {
  display: flex;
  justify-content: space-between;
}

.multipleSelect {
  max-height: 100px;
  height: 100px;
  overflow: scroll;
}

.privileges {
  width: 180px !important;
  max-height: 100px;
  overflow: scroll;
}

.title {
  @include pageTitle;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.editItem {
  display: inline;
}

.formTitle {
  @include wrapperTitle;
}

@media only screen and (max-width: 812px) {
  .tableWrapper {
    margin: 0;
    min-width: 100px;
  }

  .table {
    width: 100%;
    overflow: auto;
  }

  .actionButtonsWrapper {
    flex-direction: column;
  }

  .newButton {
    margin: 5px;
  }

  .importButton {
    margin: 5px;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
  }

  .newWrapper {
    width: 100%;
    margin-top: 15px;
  }

  .formNew {
    width: 250px;
  }

  .formItemsWrapper {
    display: block;
  }
}

.actionButtonMore {
  @include actionButtonMore;
}

.actionIconMore {
  @include actionIconMore;
}
