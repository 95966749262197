@import "../../styles/Mixins.module.scss";

.wrapper {
  @include pageWrapper;
}

.title {
  @include pageTitle;
}

.contentWrapper {
  display: flex;
  justify-content: space-around;
}

.calendarWrapper {
  @include generalWrapper;
  @include generalMargin;
  height: 100%;
  width: 100%;
  padding: 10px;
}

.actionButtonsWrapper {
  display: flex;
}

.newButton {
  margin: 0 15px;
  @include greenButon;
}

.addNewButton {
  width: 100%;
  margin: 30px 0 0;
}

.newItem {
  display: inline;
}

.tag {
  margin: 5px;
}

.importButton {
  @include greenButon;
}

.dateSelector {
  width: 100%;
}

.saveButton {
  margin-top: 10px;
  width: 100%;
}

.addButton {
  margin-top: 10px;
  width: 100%;
}

.editInfoItem {
  display: inline;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.tableWrapper {
  @include generalWrapper;
  height: 100%;
  margin: 0 0 100px 0;
  width: 100%;
}

.table {
  padding: 10px;
}

.subTitle {
  @include wrapperTitle;
  padding: 10px;
}

@media only screen and (max-width: 812px) {
  .calendarWrapper {
    max-width: 100%;
    min-width: 100%;
    margin: 0 0 10px;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
  }

  .table {
    width: 100%;
    overflow: auto;
  }

  .tableWrapper {
    max-width: 100%;
    min-width: 100%;
    margin: 0;
  }
}
