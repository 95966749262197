@import "../../../styles/Mixins.module";
@import "../../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.actionButtonMore {
  @include actionButtonMore;
}

.actionIconMore {
  @include actionIconMore;
}

.contentWrapper {
  display: flex;
  justify-content: space-around;
}

.tableWrapper {
  @include generalWrapper;
  height: 100%;
  width: 100%;
  @include generalMargin;
}

.filtersWrapper {
  display: flex;
  margin: 10px;
}

.filterWrapper {
  display: flex;
}

.filterSelect {
  width: 300px;
  @include generalSelectFilters;
  @include md {
    width: 150px;
  }
}

.headerTableWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 8px;
}

.table {
  padding: 10px;
  @include generalTable;
  width: 100%;
  overflow: auto;
}

.title {
  @include pageTitle;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.formTitle {
  @include wrapperTitle;
}

@media only screen and (max-width: 812px) {
  .contentWrapper {
    display: flex;
    flex-direction: column;
  }

  .tableWrapper {
    margin: 10px;
  }

  .filtersWrapper {
    display: flex;
    flex-direction: column;
  }

  .filterWrapper {
    display: flex;
    flex-direction: column;
    margin: 5px 15px;
  }
}
