@import "../../styles/Mixins.module";

.wrapper {
  @include pageWrapper;
}

.header {
  @include generalMargin;
  display: flex;
  justify-content: space-between;
}

.businessUnitHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionButtonMore {
  @include actionButtonMore;
}

.actionIconMore {
  @include actionIconMore;
}

.newUnitButton {
  margin-right: 20px;
  background-color: $green-color-7;
  border-color: $green-color-7;
  &:hover {
    background-color: $green-color-6;
    border-color: $green-color-6;
  }
  &:focus {
    background-color: $green-color-6;
    border-color: $green-color-6;
  }
}

.tagWrapper {
  width: 100%;
}
.channelTag {
  margin: 10px;
}
.channelIconAction {
  margin: 0 10px;
}

.companyInfoWrapper {
  @include generalMargin;
  background-color: white;
  -webkit-box-shadow: 0 10px 30px 0 rgb(24 28 33 / 5%);
  box-shadow: 0 10px 30px 0 rgb(24 28 33 / 5%);
  border-radius: 11px;
  height: 300px;
}

.businessUnitWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.channelWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.tableBusinessUnits {
  padding: 10px;
  overflow: auto;
}

.companyInfoTitle {
  @include wrapperTitle;
}

.actionButtonsWrapper {
  display: flex;
}

.saveButton {
  margin: 0 15px;
}

.newUnitItem {
  display: inline;
}

.newChannelItem {
  display: inline;
}

.addNewUnitButton {
  margin-top: 10px;
  width: 100%;
}

.editUnitButton {
  margin-top: 10px;
  width: 100%;
}

.cancelButton {
  @include cancelButton;
}

.title {
  @include pageTitle;
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.channelActionsWrapper {
  display: flex;
  justify-content: center;
}

.wrapper {
  @include pageWrapper;
}

.newForm {
  padding: 30px;
}

.title {
  @include pageTitle;
}

.actionButtonsWrapper {
  display: flex;
}

.saveButton {
  margin: 0 15px;
}

.tablesHeader {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
}

.newForm {
  padding: 30px;
}

.tableTitle {
  @include wrapperTitle;
}

.table {
  margin: 10px;
  padding: 10px;
}

.cancelButton {
  @include cancelButton;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.actionsButton {
  @include greenButon;
}

.formTitle {
  @include wrapperTitle;
}

.newItem {
  display: inline;
}

.addNewButton {
  width: 100%;
  margin: 30px 0 0;
}

@media only screen and (max-width: 812px) {
  .tableWrapper {
    margin: 10px;
  }

  .table {
    overflow: auto;
    margin: 10px;
  }

  .companyInfoWrapper {
    height: 500px;
    margin: 20px 10px;
  }

  .businessUnitWrapper {
    margin: 0 10px;
  }
  .channelWrapper {
    margin: 0 10px;
  }

  .actionButtonsWrapper {
    flex-direction: column;
  }

  .cancelButton {
    margin: 5px;
  }

  .saveButton {
    margin: 5px;
  }

  .title {
    font-size: 18px;
  }

  .companyForm {
    padding: 10px;
  }

  .channelWrapper {
    flex-direction: row;
  }
  .tableBusinessUnits {
    overflow: auto;
  }
  .tagWrapper {
    width: 200px;
  }
  .channelTag {
    margin: 10px;
    font-size: 10px;
  }
}
