@import "../../../styles/Mixins.module.scss";

.wrapper {
  @include pageWrapper;
}

.contentWrapper {
  display: flex;
  justify-content: space-around;
}

.distanceWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

.newWrapper {
  @include generalWrapper;
  @include generalMargin;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
}

.formNew {
  width: 100%;
  padding: 10px;
}

.inputNumber {
  width: 100%;
}

.title {
  @include pageTitle;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.formTitle {
  @include wrapperTitle;
}

@media only screen and (max-width: 812px) {
  .contentWrapper {
    display: flex;
  }

  .newWrapper {
    width: 100%;
    margin-top: 10px;
  }

  .formNew {
    width: 100%;
    padding: 10px;
  }

  .newWrapper {
    display: inherit;
  }
}
