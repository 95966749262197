.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
    height: 100px;
}

.logo {
  height: 100%;
}

.rightsText {
  text-align: center;
}

.sucessMessage {
  position: fixed;
  bottom: 0;
}

@media only screen and (max-width: 812px) {
    .logoWrapper {
        height: 100px;
        margin: 0 0 50px;
    }
}

