@import "../../../styles/Mixins.module";
@import "../../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.contentWrapper {
  display: flex;
  justify-content: space-around;
}

.tableWrapper {
  @include generalWrapper;
  height: 100%;
  width: 100%;
  @include generalMargin;
}

.actionButtonMore {
  @include actionButtonMore;
}

.actionIconMore {
  @include actionIconMore;
}

.filtersWrapper {
  display: flex;
  margin: 10px;
}

.filterWrapper {
  display: flex;
}

.filterSelect {
  width: 300px;
  @include generalSelectFilters;
}

.headerTableWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 8px;
}

.table {
  padding: 10px;
  @include generalTable;
}

.title {
  @include pageTitle;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.formTitle {
  @include wrapperTitle;
}

.locationWrapper {
  display: flex;
  flex-direction: column;
}

.statusWrapper {
  border: 1px solid $pink-color-8;
  border-radius: 5px;
  color: $pink-color-8;
  display: flex;
  width: 74px;
  height: 24px;
  font-size: 12px;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 812px) {
  .table {
    width: 100%;
    overflow: auto;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
  }

  .tableWrapper {
    margin: 10px;
  }

  .filtersWrapper {
    display: flex;
    flex-direction: column;
  }

  .filterWrapper {
    display: flex;
    flex-direction: column;
    margin: 5px 15px;
  }
}
