@import "../../styles/Globals.module";
@import "../../styles/Mixins.module";

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  margin: 4px 0;
  &:hover {
    background-color: $violet-color-1;
  }
}

.notReaded {
  background-color: $violet-color-1;
  &:hover {
    background-color: white;
  }
}

.title {
  font-weight: bold;
}
