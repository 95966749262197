@import "../../styles/Mixins.module.scss";

.wrapper {
  @include pageWrapper;
}

.title {
  @include pageTitle;
}

.contentWrapper {
  @include generalMargin;
  display: flex;
  justify-content: space-around;
}

.tableWrapper {
  @include generalWrapper;
  height: 100%;
  width: 100%;
}

.actionButtonsWrapper {
  display: flex;
}

.newButton {
  margin: 0 15px;
  @include greenButon;
}

.addNewButton {
  width: 100%;
  margin: 30px 0 0;
}

.newItem {
  display: inline;
}

.dateSelector {
  width: 100%;
}

.filtersWrapper {
  display: flex;
  margin: 10px;
  gap: 4px;
  @include md {
    flex-direction: column;
  }
}

.filterSelect {
  width: 250px;
  @include generalSelectFilters;
}

.filterWrapper {
  display: flex;
}

.dateSelectorFilter {
  @include dateSelectFilter;
  width: 250px;
}

.numberInput {
  width: 100%;
}

.addButton {
  margin-top: 10px;
  width: 100%;
}

.editInfoItem {
  display: inline;
}

.table {
  padding: 10px;
}

.header {
  @include generalMargin;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 812px) {
  .table {
    width: 100%;
    overflow: auto;
  }

  .tableWrapper {
    max-width: 100%;
    min-width: 100%;
    margin: 0;
  }

  .actionButtonsWrapper {
    flex-direction: column;
  }

  .newButton {
    margin: 5px;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
  }
}

.actionButtonMore {
  @include actionButtonMore;
}

.actionIconMore {
  @include actionIconMore;
}
