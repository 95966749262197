@import "../../styles/Mixins.module";
@import "../../styles/Globals.module";

.wrapper {
  @include generalWrapper;
  @include generalMargin;
}

.subTitle {
  @include wrapperTitle;
  padding: 10px;
}

.text {
  padding: 0 10px;
}

.dateSelector {
  padding: 0 10px;
}

.dateWrapper {
  display: flex;
  margin: 0 0 10px;
}

@media only screen and (max-width: 812px) {
  .wrapper {
    margin: 10px;
  }
}
