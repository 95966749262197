@import "../../styles/Mixins.module";
@import "../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.newForm {
  padding: 30px;
}

.title {
  @include pageTitle;
}

.actionButtonsWrapper {
  display: flex;
}

.saveButton {
  margin: 0 15px;
}

.tablesHeader {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
}

.tableWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.newForm {
  padding: 30px;
}

.addNewUnitButton {
  margin-top: 10px;
  width: 100%;
}

.newChannelItem {
  display: inline;
}

.tableTitle {
  @include wrapperTitle;
}

.table {
  margin: 10px;
  padding: 10px;
}

.cancelButton {
  @include cancelButton;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.businessUnitInfoWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.actionsButton {
  @include greenButon;
}

.formTitle {
  @include wrapperTitle;
}

.newItem {
  display: inline;
}

.addNewButton {
  width: 100%;
  margin: 30px 0 0;
}

@media only screen and (max-width: 812px) {
  .tableWrapper {
    margin: 10px;
  }

  .table {
    overflow: auto;
    margin: 10px;
  }
  .businessUnitInfoWrapper {
    margin: 10px;
  }
}
