@import "../../styles/Mixins.module";
@import "../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.tableWrapper {
  @include generalWrapper;
  @include generalMargin;
  height: 100%;
  width: 100%;
}

.filtersWrapper {
  display: flex;
  margin: 10px;
  gap: 4px;

  @include md {
    flex-direction: column;
  }
}

.filterSelect {
  width: 200px;
  @include generalSelectFilters;
}

.filterInput {
  @include inputFilter;
}

.filterWrapper {
  display: flex;
}

.table {
  padding: 10px;
}

.contentWrapper {
  display: flex;
  justify-content: space-around;
}

.actionButtonsWrapper {
  display: flex;
}

.title {
  @include pageTitle;
}

.newButton {
  margin: 0 15px;
  @include greenButon;
}

.header {
  @include generalMargin;
  display: flex;
  justify-content: space-between;
}

.formTitle {
  @include wrapperTitle;
}

.headerTableWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 8px;
}

.tableTitle {
  @include wrapperTitle;
}

@media only screen and (max-width: 812px) {
  .contentWrapper {
    display: flex;
    flex-direction: column;
  }

  .actionButtonsWrapper {
    flex-direction: column;
  }
}

.actionButtonMore {
  @include actionButtonMore;
}

.actionIconMore {
  @include actionIconMore;
}