@import "../../styles/Mixins.module.scss";

.wrapper {
  @include pageWrapper;
}

.contentWrapper {
  @include generalMargin;
  display: flex;
  justify-content: space-between;
  gap: 32px;

  @include md {
    flex-direction: column;
  }
}

.actionButtonsWrapper {
  display: flex;
}

.filtersWrapper {
  display: flex;
  margin: 10px;
  gap: 4px;

  @include md {
    flex-direction: column;
  }
}

.filterSelect {
  width: 200px;
  @include generalSelectFilters;
}

.filterInput {
  @include inputFilter;
}

.filterWrapper {
  display: flex;
}

.importButton {
  @include greenButon;
  margin: 0 5px;
}

.tableWrapper {
  @include generalWrapper;
  height: 100%;
  width: 70%;
  min-width: 500px;
}

.newWrapper {
  @include generalWrapper;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 400px;
}

.editButton {
  width: 100%;
  margin-top: 10px;
}

.table {
  padding: 10px;
}

.formNew {
  padding: 10px;
}

.privileges {
  width: 180px !important;
  max-height: 100px;
  overflow: scroll;
}

.title {
  @include pageTitle;
}

.header {
  @include generalMargin;
  display: flex;
  justify-content: space-between;
}

.editItem {
  display: inline;
}

.formTitle {
  @include wrapperTitle;
}

@media only screen and (max-width: 812px) {
  .tableWrapper {
    margin: 0;
    min-width: 100px;
  }

  .actionButtonsWrapper {
    flex-direction: column;
  }

  .importButton {
    margin: 5px;
  }

  .table {
    width: 100%;
    overflow: auto;
  }

  .newWrapper {
    width: 100%;
    margin-top: 15px;
  }

  .formNew {
    width: 250px;
  }
}

.actionButtonMore {
  @include actionButtonMore;
}

.actionIconMore {
  @include actionIconMore;
}
