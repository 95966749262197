@import "../../styles/Mixins.module";
@import "../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.infoWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.clientsWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.tableWrapper {
  @include generalWrapper;
  height: 100%;
  @include generalMargin;
}

.formItemsWrapper {
  display: flex;
  justify-content: space-between;
}

.doubleItemLine {
  width: 40%;
}

.inputNumber {
  width: 100%;
}

.subTitle {
  @include wrapperTitle;
  padding: 10px;
}

.clientsTitle {
  @include wrapperTitle;
}

.newForm {
  padding: 30px;
}

.contactItem {
  display: inline;
}

.addClientButton {
  width: 100%;
  margin-top: 10px;
}

.title {
  @include pageTitle;
}

.contactInfoItem {
  width: 300px;
}

.actionButtonsWrapper {
  display: flex;
}

.saveButton {
  margin: 0 15px;
}

.contactSpace {
  display: flex;
}

.clientTable {
  margin: 10px;
  padding: 10px;
}

.tablesHeader {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
}

.cancelButton {
  @include cancelButton;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.actionsButton {
  @include greenButon;
}

.formTitle {
  @include wrapperTitle;
}

.table {
  padding: 10px;
}

@media only screen and (max-width: 812px) {
  .infoWrapper {
    margin: 10px;
  }

  .clientsWrapper {
    margin: 10px;
  }

  .table {
    width: 100%;
    overflow: auto;
  }

  .tableWrapper {
    max-width: 100%;
    min-width: 100%;
    margin: 0;
  }

  .clientTable {
    overflow: auto;
    margin: 10px;
  }
}
