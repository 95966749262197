.wrapper {
  display: flex;
  align-items: center;
}

.fileSelector {
  margin: 0 15px;
}

.errorAlert {
  margin: 15px;
}

.errorLineWrapper {
  display: flex;
  align-items: center;
}
