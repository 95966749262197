@import "../../styles/Mixins.module.scss";

.wrapper {
  width: 100%;
  padding: 10px;
}

.addButton {
  @include greenButon;
}

.title {
  @include pageTitle;
}

.header {
  display: flex;
  justify-content: space-between;
}

.filterInput {
  margin: 8px;
}

.filterSelect {
  width: 100%;
  margin: 8px;
}

.actionWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.filtersWrapper {
  display: flex;
  flex-direction: row;
}
