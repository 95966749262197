@import "../../../styles/Mixins.module";
@import "../../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.tableWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.addButton {
  width: 100%;
  margin-top: 10px;
}

.tablesHeader {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
}

.tablesTitle {
  @include wrapperTitle;
}

.table {
  margin: 10px;
  padding: 10px;
  @include md {
    overflow: auto;
    margin: 10px;
  }
}

.actionsButton {
  @include greenButon;
}

.title {
  @include pageTitle;
}

.infoWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.itemWrapper {
  display: flex;
  justify-content: space-around;
}

.actionButtonsWrapper {
  display: flex;
}

.saveButton {
  margin: 0 15px;
}

.cancelButton {
  @include cancelButton;
}

.clusterForm {
  padding: 30px;
}

.formItem {
  @include md {
    margin: 0 5px;
  }
}

.itemWrapper {
  @include md {
    display: inherit;
  }
}
.inputNumber {
  width: 100%;
}
