@import "../../styles/Mixins.module.scss";

.wrapper {
  @include pageWrapper;
}

.contentWrapper {
  @include generalMargin;
  gap: 32px;
  display: flex;
  justify-content: space-between;
  @include md {
    flex-direction: column;
  }
}

.tableRolesWrapper {
  @include generalWrapper;
  height: 100%;
  width: 70%;
  @include md {
    max-width: 100%;
    min-width: 100%;
    margin: 0;
  }
}

.filtersWrapper {
  display: flex;
  margin: 10px;
  gap: 4px;

  @include md {
    flex-direction: column;
  }
}

.filterSelect {
  width: 200px;
  @include generalSelectFilters;
}

.filterInput {
  @include inputFilter;
}

.filterWrapper {
  display: flex;
}

.newRolWrapper {
  @include generalWrapper;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 400px;
  @include md {
    width: 100%;
  }
}

.tableRoles {
  padding: 10px;
  @include md {
    overflow: auto;
    width: 100%;
  }
}

.formNewRol {
  padding: 10px;
  @include md {
    width: 250px;
  }
}

.privileges {
  width: 180px !important;
  max-height: 100px;
  overflow: scroll;
}

.title {
  @include pageTitle;
}

.header {
  @include generalMargin;
  @include md {
    @include generalMargin;
  }
  display: flex;
  @include generalMargin;
}

.selector {
  display: flex;
  width: fit-content;
  margin: 0 0 0 5px;
}

.editItem {
  display: inline;
}

.formTitle {
  @include wrapperTitle;
}

.actionButtonMore {
  @include actionButtonMore;
}

.actionIconMore {
  @include actionIconMore;
}
