@import "../../../styles/Mixins.module.scss";

.wrapper {
  @include pageWrapper;
}

.subTitle {
  @include wrapperTitle;
  padding: 10px;
}

.saveButton {
  @include greenButon;
}

.formWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.newForm {
  padding: 10px;
}

.dateSelector {
  width: 100%;
}

.multipleSelect {
  max-height: 100px;
  overflow: scroll;
}

.title {
  @include pageTitle;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.formTitle {
  @include wrapperTitle;
}
