.chatContainer {
  max-width: 90%;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
}

.chatMessages {
  padding: 10px;
  height: 500px; /* ajusta la altura según tus necesidades */
  overflow-y: auto;
}

.message {
  padding: 8px 12px;
  margin-bottom: 8px;
  border-radius: 5px;
  word-wrap: break-word;
}

.user {
  background-color: #007bff;
  color: #fff;
  align-self: flex-end;
}

.bot {
  background-color: #28a745;
  color: #fff;
  align-self: flex-start;
}

.chatInput {
  display: flex;
  align-items: center;
  padding: 10px;
}

.chatInput input {
  flex: 1;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 10px;
}
