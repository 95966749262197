@import "../../styles/Mixins.module";
@import "../../styles/Globals.module";

.tablesHeader {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
}

.newItem {
  display: inline;
}

.addNewButton {
  width: 100%;
  margin: 30px 0 0;
}

.tableWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.tableTitle {
  @include wrapperTitle;
}

.table {
  margin: 10px;
  padding: 10px;
  min-height: 300px;
}

.actionsButton {
  @include greenButon;
  margin: 0 5px;
}

.actionsButtonWrapper {
  display: flex;
}

.filterInput {
  width: 250px;
  margin: 4px;
}

.filterWrapper {
  margin: 0 20px;
}

@media only screen and (max-width: 812px) {
  .header {
    margin: 10px;
  }

  .tableWrapper {
    margin: 10px;
  }

  .table {
    overflow: auto;
    margin: 10px;
  }

  .formItem {
    margin: 0 5px;
  }
}
