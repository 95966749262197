@import "../../styles/Mixins.module";

.wrapper {
  @include pageWrapper;
}

.branchOfficeSelector {
  max-width: 200px;
  max-height: 80px;
  overflow-y: auto;
}

.filtersWrapper {
  display: flex;
  margin: 10px;
  gap: 4px;

  @include md {
    flex-direction: column;
  }
}

.filterSelect {
  width: 200px;
  @include generalSelectFilters;
}

.filterInput {
  @include inputFilter;
}

.filterWrapper {
  display: flex;
}

.privilegesSelector {
  max-width: 200px;
  max-height: 80px;
  overflow-y: auto;
}

.contentWrapper {
  @include generalMargin;
  gap: 32px;
  display: flex;
  justify-content: space-between;
  @include md {
    flex-direction: column;
  }
}

.actionButtonsWrapper {
  display: flex;
}

.newButton {
  margin: 0 15px;
  @include greenButon;
}

.tableUsersWrapper {
  @include generalWrapper;
  overflow: auto;
  height: 100%;
  width: 100%;
  @include md {
    max-width: 100%;
    min-width: 100%;
    margin: 0;
  }
}

.newUserWrapper {
  @include generalWrapper;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @include md {
    max-width: 100%;
  }
}

.formNewUser {
  padding: 10px;
  @include md {
    width: 250px;
  }
}

.tableUsers {
  padding: 10px;
  @include md {
    overflow: auto;
    width: 100%;
  }
}

.title {
  @include pageTitle;
}

.header {
  @include generalMargin;
  display: flex;
  justify-content: space-between;
}

.selector {
  display: flex;
  width: fit-content;
  margin: 0 0 0 5px;
}

.formTitle {
  @include wrapperTitle;
}

.rolTag {
  @include md {
    margin: 5px;
  }
}

.actionButtonMore {
  @include actionButtonMore;
}

.actionIconMore {
  @include actionIconMore;
}