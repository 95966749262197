@import "../../styles/Mixins.module.scss";

.wrapper {
  @include pageWrapper;
}

.contentWrapper {
  display: flex;
  justify-content: space-around;
}

.actionButtonsWrapper {
  display: flex;
}

.importButton {
  @include greenButon;
}

.dateSelector {
  width: 100%;
}

.newWrapper {
  @include generalWrapper;
  @include generalMargin;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.formNew {
  padding: 10px;
  width: 100%;
}

.formItemsWrapper {
  display: flex;
  justify-content: space-between;
}

.title {
  @include pageTitle;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.editItem {
  display: inline;
}

.formTitle {
  @include wrapperTitle;
}

@media only screen and (max-width: 812px) {
  .contentWrapper {
    display: flex;
  }

  .newWrapper {
    width: 100%;
    margin: 15px 0;
  }

  .formNew {
    width: 250px;
  }

  .formItemsWrapper {
    display: block;
  }
}
