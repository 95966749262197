@import "../../styles/Mixins.module";
@import "../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.articleInfoWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.skusFormItem {
  display: inline;
}

.skusSpace {
  display: flex;
}

.skusForm {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.skusItemsWrapper {
  display: flex;
  justify-content: space-between;
}


.itemsWrapper {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  @include sm(){
    display: block;
  }
}

.doubleItemLine {
  width: 60%;
  @include sm(){
    width: 100%;
  }
}

.skuInfoItem {
  width: 150px !important;
  margin: 5px;
}

.numberInput {
  width: 100%;
}

.newSkuButton {
  width: 100%;
}

.skusTitle {
  @include wrapperTitle;
}

.addAttributeForm {
  padding: 0 20px;
}

.suppliersWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.dateSelector {
  width: 100%;
}

.addButton {
  width: 100%;
  margin-top: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.actionButtonsWrapper {
  display: flex;
}

.saveButton {
  margin: 0 15px;
}

.cancelButton {
  @include cancelButton;
}

.articleForm {
  padding: 30px;
}

.title {
  @include pageTitle;
}

.header {
  @include generalMargin;
}

.contactInfoItem {
  width: 300px;
}

.articleInfoItem {
  width: 150px !important;
}

.contactSpace {
  display: flex;
}

.suppliersTable {
  margin: 10px;
  padding: 10px;
}

.tablesHeader {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
}

.skusWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.skusTable {
  margin: 10px;
  padding: 10px;
}

.attributesWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.tablesTitle {
  @include wrapperTitle;
}

.attributesTable {
  margin: 10px;
  padding: 10px;
}

.actionsButton {
  background-color: $green-color-7;
  border-color: $green-color-7;
  &:hover {
    background-color: $green-color-6;
    border-color: $green-color-6;
  }
  &:focus {
    background-color: $green-color-6;
    border-color: $green-color-6;
  }
}

.formTitle {
  @include wrapperTitle;
}

@media only screen and (max-width: 812px) {
  .articleInfoWrapper {
    margin: 10px;
  }

  .suppliersWrapper {
    margin: 10px;
  }

  .suppliersTable {
    overflow: auto;
    margin: 10px;
  }

  .articleInfoItem {
    width: 100px;
  }

  .skusTable {
    overflow: auto;
    margin: 10px;
  }

  .attributesWrapper {
    margin: 10px;
  }

  .attributesTable {
    overflow: auto;
    margin: 10px;
  }

  .attributesTable {
    overflow: auto;
    margin: 10px;
  }

  .skusFormItem {
    margin: 5px;
  }

  .skusWrapper {
    margin: 10px;
  }
}
