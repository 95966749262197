@import "../../../styles/Mixins.module.scss";

.wrapper {
  @include pageWrapper;
}

.contentWrapper {
  display: flex;
  justify-content: space-around;
}

.saveButton {
  @include greenButon;
}

.subTitle {
  @include wrapperTitle;
  padding: 10px;
}

.newWrapper {
  @include generalWrapper;
  @include generalMargin;
  display: flex;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.formNew {
  padding: 10px;
  width: 100%;
}

.title {
  @include pageTitle;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.formTitle {
  @include wrapperTitle;
}
.inputNumber {
  width: 100%;
}

@media only screen and (max-width: 812px) {
  .contentWrapper {
    display: flex;
  }

  .newWrapper {
    width: 100%;
    margin: 15px 0 0 0;
  }
}
