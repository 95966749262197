@import "../../../../styles/Mixins.module";
@import "../../../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.newForm {
  padding: 10px;
}

.title {
  @include pageTitle;
}

.actionButtonsWrapper {
  display: flex;
}

.saveButton {
  margin: 0 15px;
}

.doubleItemLine {
  width: 40%;
}

.inputNumber {
  width: 100%;
}

.tablesHeader {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
}

.tableWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.formItemsWrapper {
  display: flex;
  justify-content: space-between;
}

.dateSelector {
  width: 100%;
}

.addNewButton {
  margin-top: 10px;
  width: 100%;
}

.newChannelItem {
  display: inline;
}

.tableTitle {
  @include wrapperTitle;
}

.table {
  margin: 10px;
  padding: 10px;
}

.tag {
  margin: 5px;
}

.cancelButton {
  @include cancelButton;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.infoWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.itemWrapper {
  display: flex;
  justify-content: space-around;
}

.actionsButton {
  @include greenButon;
}

.formTitle {
  @include wrapperTitle;
}

@media only screen and (max-width: 812px) {
  .tableWrapper {
    margin: 10px;
  }

  .table {
    overflow: auto;
    margin: 10px;
  }

  .itemWrapper {
    display: inherit;
  }

  .infoWrapper {
    margin: 10px;
  }

  .formItem {
    margin: 0 5px;
  }
}

.inputNumber {
  width: 100%;
}
