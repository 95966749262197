@import "../../styles/Mixins.module";
@import "../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.newForm {
  padding: 30px;
}

.distanceWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

.filtersWrapper {
  display: flex;
  margin: 10px;
  gap: 4px;
  padding: 0 10px;

  @include md {
    flex-direction: column;
  }
}

.filterSelect {
  width: 200px;
  @include generalSelectFilters;
}

.filterInput {
  @include inputFilter;
}

.dateSelector {
  width: 100%;
  @include inputFilter;
}

.filterWrapper {
  display: flex;
}

.importButton {
  @include greenButon;
  margin: 0 5px;
}

.title {
  @include pageTitle;
}

.actionButtonsWrapper {
  display: flex;
}

.saveButton {
  margin: 0 15px;
}

.tablesHeader {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
}

.tableWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.newItem {
  display: inline;
}

.addNewButton {
  margin-top: 10px;
  width: 100%;
}

.newChannelItem {
  display: inline;
}

.tableTitle {
  @include wrapperTitle;
}

.table {
  margin: 10px;
  padding: 10px;
}

.tag {
  margin: 5px;
}

.cancelButton {
  @include cancelButton;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.depositInfoWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.itemWrapper {
  display: flex;
  justify-content: space-around;
}

.actionsButton {
  @include greenButon;
}

.formTitle {
  @include wrapperTitle;
}

@media only screen and (max-width: 812px) {
  .table {
    overflow: auto;
    margin: 10px;
  }

  .itemWrapper {
    display: inherit;
  }

  .formItem {
    margin: 0 5px;
  }
}

.inputNumber {
  width: 100%;
}
