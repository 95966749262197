.wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.icon {
  font-size: 32px;
}
