@import "../../styles/Mixins.module.scss";

.wrapper {
  @include pageWrapper;
}

.contentWrapper {
  display: flex;
  justify-content: space-around;
}

.filtersWrapper {
  display: flex;
  margin: 10px;
  gap: 4px;

  @include md {
    flex-direction: column;
  }
}

.filterSelect {
  width: 200px;
  @include generalSelectFilters;
}

.filterInput {
  @include inputFilter;
}

.filterWrapper {
  display: flex;
}

.subTitle {
  @include wrapperTitle;
  padding: 0;
}

.headerTableWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dateSelector {
  @include dateSelectFilter;
  width: 100%;
}

.actionButtonsWrapper {
  display: flex;
}

.subtitle {
  @include wrapperTitle;
  padding: 10px;
}

.importButton {
  @include greenButon;
}

.newWrapper {
  @include generalWrapper;
  @include generalMargin;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.formNew {
  padding: 10px;
  width: 100%;
}

.formItemsWrapper {
  display: flex;
  justify-content: space-between;
}

.doubleItemLine {
  width: 40%;
}

.inputNumber {
  width: 100%;
}

.multipleSelect {
  max-height: 100px;
  overflow: scroll;
}

.title {
  @include pageTitle;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.editItem {
  display: inline;
}

.formTitle {
  @include wrapperTitle;
}

@media only screen and (max-width: 812px) {
  .contentWrapper {
    display: flex;
    flex-direction: column;
  }

  .newWrapper {
    width: 100%;
    margin-top: 15px;
  }

  .formNew {
    width: 250px;
  }

  .formItemsWrapper {
    display: block;
  }
}
