@import "../../../styles/Mixins.module";
@import "../../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.contentWrapper {
  display: flex;
  justify-content: space-around;
}


.tableWrapper {
  @include generalWrapper;
  height: 100%;
  width: 100%;
  @include generalMargin;
}

.filtersWrapper {
  display: flex;
  margin: 10px;
  gap: 4px;
}

//.row {
//  background-color: #ece5f6;
//}

.stockWrapper {
  display: flex;
  flex-direction: column;
}

.filterWrapper {
  display: flex;
}

.filterSelect {
  width: 300px;
  @include generalSelectFilters;
}

.headerTableWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 8px;
}

.table {
  padding: 10px;
  @include generalTable;
}

.title {
  @include pageTitle;
}

.newOrderButton {
  @include pinkButton;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.formTitle {
  @include wrapperTitle;
}

@media only screen and (max-width: 812px) {
  .table {
    width: 100%;
    overflow: auto;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
  }

  .tableWrapper {
    margin: 10px;
  }

  .filtersWrapper {
    display: flex;
    flex-direction: column;
  }

  .filterWrapper {
    display: flex;
    flex-direction: column;
    margin: 5px 15px;
  }
}
