@import "../../../styles/Mixins.module";
@import "../../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.title {
  @include pageTitle;
}

.infoWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.itemWrapper {
  display: flex;
  justify-content: space-around;
}

.actionButtonsWrapper {
  display: flex;
}

.saveButton {
  margin: 0 15px;
}

.cancelButton {
  @include cancelButton;
}

.acceptedPaymentMethodForm {
  padding: 30px;
}

.formItem {
  @include md {
    margin: 0 5px;
  }
}

.itemWrapper {
  @include md {
    display: inherit;
  }
}
.inputNumber {
  width: 100%;
}
