@import "../../../styles/Mixins.module";
@import "../../../styles/Globals.module";

.tableWrapper {
  @include generalWrapper;
  justify-content: space-around;
  width: 100%;
  margin: 12px;
  @include md {
    margin: 30px;
  }
}

.headerTableWrapper {
  @include md {
    margin: 10px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 8px;
}

.table {
  overflow: auto;
  padding: 10px;
  @include generalTable;
}

.title {
  @include pageTitle;
  display: flex;
  margin: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  color: $violet-color-10;
}
