$blue-color-1: #f0f9ff;
$blue-color-2: #edf8ff;
$blue-color-3: #c4e5ff;
$blue-color-4: #95c7f5;
$blue-color-5: #68a6e8;
$blue-color-6: #315989;
$blue-color-7: #2b65b5;
$blue-color-8: #1b478f;
$blue-color-9: #0f2e69;
$blue-color-10: #091b42;

$violet-color-1: #f6f3fb;
$violet-color-5: #95a7e5;
$violet-color-7: #707eae;
$violet-color-10: #2d313e;

$pink-color-1: #f9749b;
$pink-color-3: #ef648b;
$pink-color-6: #ec5dd2;
$pink-color-8: #ef5bcd;
$pink-color-10: #ed58d2;

$green-color-1: #fafff0;
$green-color-2: #edffcc;
$green-color-3: #d7faa0;
$green-color-4: #baed72;
$green-color-5: #9ce048;
$green-color-6: #7ed321;
$green-color-7: #5ead13;
$green-color-8: #418708;
$green-color-9: #296101;
$green-color-10: #163b00;
