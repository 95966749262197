@import "../../styles/Mixins.module";
@import "../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.articleInfoWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.articlesWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.cascader {
  width: 150px !important;
}

.articlesTitle {
  @include wrapperTitle;
}

.pricesItem {
  width: 300px;
}

.itemsWrapper {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  @include sm(){
    display: block;
  }
}

.doubleItemLine {
  width: 60%;
  @include sm(){
    width: 100%;
  }
}

.newForm {
  padding: 30px;
}

.title {
  @include pageTitle;
}

.header {
  @include generalMargin;
  display: flex;
  justify-content: space-between;
}

.saveButton {
  @include greenButon;
}

.supplierWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.suppliersTitle {
  @include wrapperTitle;
}
.attributesWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.attributesTitle {
  @include wrapperTitle;
}

.supplierInfoItem {
  width: 170px !important;
}

.supplierSpace {
  display: flex;
}

.suppliersForm {
  margin: 10px;
  padding: 10px;
  overflow: auto;
}

.attributesForm {
  margin: 10px;
  padding: 10px;
}

.skusWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.skusTitle {
  @include wrapperTitle;
}

.skusFormItem {
  display: inline;
}

.skusSpace {
  display: flex;
}

.skusForm {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.skusItemsWrapper {
  display: flex;
  justify-content: space-between;
}

.skuInfoItem {
  width: 150px !important;
  margin: 5px;
}

.skuTable {
  margin: 10px;
  padding: 10px;
}

.articleForm {
  margin: 10px;
  padding: 10px;
}

.ant-form-item-label {
  overflow: inherit !important;
}

.actionsButton {
  @include greenButon;
}

.newSkuButton {
  width: 100%;
}

.skuTableWrapper {
  margin: 10px;
}

.numberInput {
  width: 100%;
}

.addSkuButton {
  margin: 10px;
  @include greenButon;
}

.formTitle {
  @include wrapperTitle;
}

@media only screen and (max-width: 812px) {
  .articleInfoWrapper {
    margin: 10px;
  }
  .supplierWrapper {
    margin: 10px;
  }
  .attributesWrapper {
    margin: 10px;
  }

  .supplierInfoItem {
    width: 100px;
  }

  .suppliersForm {
    overflow: auto;
    margin: 10px;
  }

  .attributesForm {
    overflow: auto;
    margin: 10px;
  }

  .skusFormItem {
    margin: 5px;
  }

  .skusWrapper {
    margin: 10px;
  }

  .skuTable {
    overflow: auto;
  }

  .skusForm {
    overflow: auto;
    margin: 10px;
  }

  .articleForm {
    overflow: auto;
    margin: 10px;
  }

  .articlesWrapper {
    margin: 10px;
  }
}
