@import "../../styles/Mixins.module";
@import "../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.contentWrapper {
  display: flex;
  justify-content: space-around;
}

.tableWrapper {
  @include generalWrapper;
  height: 100%;
  width: 100%;
  @include generalMargin;
}

.filtersWrapper {
  display: flex;
  margin: 10px;
  @include md {
    display: flex;
    flex-direction: column;
  }
}

.filterWrapper {
  display: flex;
  @include md {
    display: flex;
    flex-direction: column;
    margin: 5px 15px;
  }
}

.completedCheckbox {
  :global(.ant-checkbox-checked)::after {
    border-color: $pink-color-1;
  }
  :global(.ant-checkbox-inner) {
    border-color: $pink-color-1;
  }
  :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: $pink-color-1;
  }
}

.dateSelector {
  margin: 0 5px;
  @include dateSelectFilter;
}

.filterSelect {
  margin: 0 5px;
  width: 150px;
  @include generalSelectFilters;
}

.graphicsWrapper {
  @include generalWrapper;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  @include generalMargin;
}

.graphicsTitle {
  @include wrapperTitle;
}

.pieChart {
  max-width: 400px !important;
  max-height: 400px !important;
}

.headerTableWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.newWrapper {
  display: flex;
}

.actionButtonMore {
  @include actionButtonMore;
}

.actionIconMore {
  @include actionIconMore;
}

.locationWrapper {
  display: flex;
  flex-direction: column;
}

.saveButton {
  margin-top: 10px;
  width: 100%;
}

.table {
  padding: 10px;
  @include generalTable;
  width: 100%;
  overflow: auto;
}

.statusWrapper {
  border: 1px solid $pink-color-8;
  border-radius: 5px;
  color: $pink-color-8;
  display: flex;
  width: 74px;
  height: 24px;
  font-size: 12px;
  justify-content: center;
  align-items: center;
}

.actionButtonsWrapper {
  display: flex;
}

.rulesDropdown {
  background-color: $violet-color-7;
  border-radius: 10px;
  color: white;
}

.newButton {
  @include pinkButton;
  margin: 0 10px;
}

.importButton {
  @include greenButon;
}

.title {
  @include pageTitle;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.formTitle {
  @include wrapperTitle;
}

@media only screen and (max-width: 812px) {
  .table {
    width: 100%;
    overflow: auto;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
  }

  .actionButtonsWrapper {
    flex-direction: column;
  }

  .newButton {
    margin: 5px;
  }

  .importButton {
    margin: 5px;
  }

  .tableWrapper {
    margin: 10px;
  }

  .graphicsWrapper {
    margin: 10px;
  }
}
