@import "../../styles/Mixins.module";
@import "../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.formWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.chartWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.text {
  margin: 10px;
}

.timeLine {
  padding: 10px;
}

.subTitle {
  @include wrapperTitle;
  padding: 10px;
}

.newForm {
  padding: 0 30px 10px;
}

.title {
  @include pageTitle;
}

.header {
  @include generalMargin;
  display: flex;
  justify-content: space-between;
}

.articleForm {
  margin: 10px;
  padding: 10px;
}

.formItemsWrapper {
  display: flex;
  justify-content: space-between;
}

.table {
  padding: 10px;
}


.dateSelector {
  width: 100%;
}

.inputNumber {
  width: 100%;
}

.buyTogetherItems {
  width: 40%;
}

.doubleItemLine {
  width: 40%;
}

.binesLine {
  margin: 0 10px;
  width: 30%;
}

.binesWrapper {
  display: flex;
}

.ant-form-item-label {
  overflow: inherit !important;
}

.actionsButton {
  @include greenButon;
  margin: 10px 0;
}

.formTitle {
  @include wrapperTitle;
}

.saveButton {
  margin: 0 15px;
}

.cancelButton {
  @include cancelButton;
}

@media only screen and (max-width: 812px) {
  .formWrapper {
    margin: 10px;
  }

  .chartWrapper {
    margin: 10px;
    display: block;
  }

  .formItemsWrapper {
    display: block;
  }

  .doubleItemLine {
    width: 100%;
  }

  .infoWrapper {
    margin: 10px;
  }
}
