@import "../../../styles/Mixins.module";
@import "../../../styles/Globals.module";

.wrapper {
  @include generalWrapper;
  width: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  margin: 12px;
  padding: 24px;
  @include xs {
    margin: 4px;
  }
}

.title {
  //font-family: Lato, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  color: $violet-color-7;
}

.description {
  //font-family: Lato, serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: left;
  color: $violet-color-10;
}
