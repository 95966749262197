@import "../../styles/Mixins.module";
@import "../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.downloadButton {
  @include greenButon;
  margin: 0 5px;
}

.repositionOrderWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.articlesWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.newForm {
  padding: 30px;
}

.articlesTitle {
  @include wrapperTitle;
}

.title {
  @include pageTitle;
}

.actionButtonsWrapper {
  display: flex;
}

.saveButton {
  margin: 0 15px;
}

.tablesHeader {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
}

.cancelButton {
  @include cancelButton;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.articleTable {
  margin: 10px;
  padding: 10px;
}

.actionsButton {
  @include greenButon;
}

.formTitle {
  @include wrapperTitle;
}

@media only screen and (max-width: 812px) {
  .repositionOrderWrapper {
    margin: 10px;
  }

  .articleTable {
    overflow: auto;
    margin: 10px;
  }

  .articlesWrapper {
    margin: 10px;
  }
}
