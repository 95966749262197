.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.logo {
  height: 100%;
}

.formWrapper {
  width: 380px;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #ccc;
  box-shadow: 7px 7px 15px #8d857f;
}

.lockIcon {
  font-size: 72px;
  margin: 10px;
}

.title {
  font-size: 24px;
  width: 80%;
}

.description {
  width: 80%;
}

.submitButton {
  width: 80%;
  margin: 10px 0;
}

.passwordInput {
  width: 80%;
}

@media only screen and (max-width: 812px) {
  .formWrapper {
    width: 280px;
    height: 450px;
  }
}
