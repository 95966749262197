@import "../../styles/Mixins.module";
@import "../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.newForm {
  padding: 30px;
}

.filtersWrapper {
  display: flex;
  margin: 10px;
  align-items: center;
}

.filterWrapper {
  display: flex;
  margin: 8px;
}

.filterSelect {
  width: 300px;
  @include generalSelectFilters;
}

.title {
  @include pageTitle;
}

.actionButtonsWrapper {
  display: flex;
}

.actionButtonsPreview {
  display: flex;
}

.saveButton {
  @include pinkButton;
}

.backButton {
  margin: 0 15px;
  @include cancelButton;
}

.tablesHeader {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
}

.tableWrapper {
  @include generalWrapper;
  height: 100%;
  width: 100%;
}

.stockWrapper {
  display: flex;
  flex-direction: column;
}

.addSkuButton {
  @include pinkButton;
  border-radius: 10px;
  border-color: $pink-color-8;
  background: #ffffff;
  color: $pink-color-8;
}

.table {
  margin: 10px;
  @include generalTable;
}

.cancelButton {
  @include cancelButton;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.subTitle {
  @include wrapperTitle;
}

.tableArticles {
  padding: 10px;
}

.contentWrapper {
  display: flex;
  justify-content: space-around;
  @include generalMargin;
}

.itemWrapper {
  display: flex;
  justify-content: space-around;
}

.actionsButton {
  @include greenButon;
}

.formTitle {
  @include wrapperTitle;
}

.formItem {
  width: 150px !important;
}

.branchOfficeWrapper {
  display: flex;
}

.newOrderItem {
  margin: 5px;
  display: inline;
}

.tablesHeader {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
}

.addButton {
  @include greenButon;
}
.actionWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 812px) {
  .tableWrapper {
    margin: 10px;
  }

  .table {
    overflow: auto;
    margin: 10px;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }

  .filtersWrapper {
    flex-direction: column;
  }
}
