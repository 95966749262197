@import "../../styles/Mixins.module";
@import "../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.formWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.subTitle {
  @include wrapperTitle;
  padding: 10px;
}

.newForm {
  padding: 0 30px 10px;
}

.title {
  @include pageTitle;
}

.header {
  @include generalMargin;
  display: flex;
  justify-content: space-between;
}

.saveButton {
  @include greenButon;
}

.articleForm {
  margin: 10px;
  padding: 10px;
}

.formItemsWrapper {
  display: flex;
  justify-content: space-between;
}

.dateSelector {
  width: 100%;
}

.buyTogetherItems {
  width: 40%;
}

.doubleItemLine {
  width: 40%;
  margin: 5px;
}

.tripleItemLine {
  width: 30%;
  margin: 5px;
}

.cascaderSelector {
  max-width: 200px;
  width: 200px !important;
}

.ant-form-item-label {
  overflow: inherit !important;
}

.actionsButton {
  @include greenButon;
  margin: 10px 0;
}

.formTitle {
  @include wrapperTitle;
}

.table {
  padding: 10px;
}

@media only screen and (max-width: 812px) {
  .formItemsWrapper {
    display: block;
  }

  .doubleItemLine {
    width: 100%;
  }

  .infoWrapper {
    margin: 10px;
  }

  .table {
    width: 100%;
    overflow: auto;
  }
}
