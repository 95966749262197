@import "../../styles/Globals.module";
@import "../../styles/Mixins.module";

.navBar {
  background-color: #ffffff;
  color: $violet-color-7;
  border-radius: 25px;
  display: flex;
  align-items: center;
  margin: 32px 20px;
  width: 100%;
  min-height: 52px;

  @include md {
    flex-direction: column;
    width: 80%;
  }
}

.menuWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 16px;

  @include md {
    padding: 0 12px;
  }
}

.notificationsWrapper {
  min-width: 300px;
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 3px solid $violet-color-1;
  border-radius: 10px;
  padding: 20px;
}

.notificationTitle {
  padding: 10px;
}

.notificationSeparator {
  margin: 8px 0;
}

.notificationIcon {
  color: $violet-color-7;
  font-size: 24px;
}

.iconsWrapper {
  display: flex;
  gap: 6px;
}

.profileWrapper {
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.profileButtons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.cancelButton {
  @include cancelButton;
}

.headWrapper {
  height: 65px; /* define for mobile*/
}

.navigationItem {
  float: left;
  display: flex;
  align-items: center;
}

.navigationItem > a {
  color: white;
}

.logo {
  width: 110px;
  height: 100%;
  margin: 0 25px;
}

.selected {
  background: linear-gradient(262.32deg, #ed58d2 1.24%, #f9749b 93.71%);
  color: #ffffff !important;
  border-radius: 10px;

  :global(.ant-menu-title-content) {
    color: #ffffff !important;
  }

  :global(.ant-menu-item-icon) {
    color: #ffffff !important;
  }
}

.menu {
  @include md {
    width: 100%;
    overflow-y: hidden;
    padding: 16px 0;
    margin: 0;
  }
}

.menu a:hover {
  color: $violet-color-7 !important;
}

.subMenusWrapper {
  display: flex;
  overflow: auto;
  color: $violet-color-7;

  :global(.ant-menu-item-icon) {
    color: $violet-color-7;
  }
  :global(.ant-menu-title-content) {
    color: $violet-color-7;
  }
}

.subMenu {
  margin: 5px !important;
  padding: 15px !important;
  color: $violet-color-7;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  //workaround menu hidden.
  height: inherit !important;
  position: inherit !important;
  opacity: inherit !important;
  order: inherit !important;
  overflow-y: inherit !important;
  pointer-events: inherit !important;
}

.subMenu::after {
  content: none !important;
}

.subMenu:hover {
  color: $violet-color-5 !important;
}

.filterWrapper {
  display: flex;
  margin: 8px 4px;
}

.filterSelect {
  width: 300px;
  @include generalSelectFilters;
  @include md {
    width: 100%;
  }
}
