@import "../../styles/Mixins.module";
@import "../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.supplierInfoWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.contactWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.contactsTitle {
  @include wrapperTitle;
}

.articlesWrapper {
  @include generalWrapper;
  @include generalMargin;
}

.articlesTitle {
  @include wrapperTitle;
}

.newForm {
  padding: 30px;
}

.title {
  @include pageTitle;
}

.header {
  @include generalMargin;
  display: flex;
  justify-content: space-between;
}

.saveButton {
  @include greenButon;
}

.contactInfoItem {
  display: inline;
  width: 200px;
}

.articleInfoItem {
  width: 170px !important;
}

.contactSpace {
  display: flex;
}

.contactForm {
  margin: 10px;
  padding: 10px;
}

.articleForm {
  margin: 10px;
  padding: 10px;
  overflow: auto;
}

.ant-form-item-label {
  overflow: inherit !important;
}

.actionsButton {
  @include greenButon;
  margin: 10px 0;
}

.formTitle {
  @include wrapperTitle;
}

@media only screen and (max-width: 812px) {
  .supplierInfoWrapper {
    margin: 10px;
  }
  .contactWrapper {
    margin: 10px;
  }

  .contactInfoItem {
    width: 100px;
  }
  .contactForm {
    overflow: auto;
    margin: 10px;
  }

  .articleInfoItem {
    width: 100px;
  }
  .articleForm {
    overflow: auto;
    margin: 10px;
  }

  .articlesWrapper {
    margin: 10px;
  }
}
