.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 100%;
}

.rightsText {
  text-align: center;
}

@media only screen and (max-width: 812px) {
  .logoWrapper {
    height: 100px;
    margin: 0 0 10px;
  }
}
