@import "./../../styles/Globals.module.scss";
@import "../../styles/Mixins.module.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 30px;
}

.goButton {
  width: 100%;
}

.form {
  min-width: 500px;
}

.loginButton {
  @include greenButon;
}

.forgotPasswordButton {
  background-color: $blue-color-7;
  border-color: $blue-color-7;
}

.forgotPasswordButton:hover {
  background-color: $blue-color-6;
  border-color: $blue-color-6;
}

@media only screen and (max-width: 812px) {
  .form {
    min-width: 100%;
    padding: 0 30px;
  }
}
