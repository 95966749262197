@import "../../styles/Mixins.module.scss";

.wrapper {
  @include pageWrapper;
}

.contentWrapper {
  @include generalMargin;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  @include md {
    flex-direction: column;
  }
}

.tableWrapper {
  @include generalWrapper;
  height: 100%;
  width: 70%;
  min-width: 500px;
}

.newPricingTypeWrapper {
  @include generalWrapper;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 400px;
}

.addButton {
  margin-top: 10px;
  width: 100%;
}

.editInfoItem {
  display: inline;
}

.table {
  padding: 10px;
}

.formNewPricingType {
  padding: 10px;
}

.title {
  @include pageTitle;
}

.header {
  @include generalMargin;
  display: flex;
  justify-content: space-between;
}

.formTitle {
  @include wrapperTitle;
}

@media only screen and (max-width: 812px) {
  .table {
    width: 100%;
    overflow: auto;
  }

  .tableWrapper {
    width: 100%;
    min-width: 100%;
  }

  .newPricingTypeWrapper {
    width: 100%;
  }

  .formNewPricingType {
    width: 250px;
  }
}
