@import "../../styles/Mixins.module";

.wrapper {
  @include pageWrapper;
}

.goalsWrapper {
  @include generalMargin;
  background-color: white;
  -webkit-box-shadow: 0 10px 30px 0 rgb(24 28 33 / 5%);
  box-shadow: 0 10px 30px 0 rgb(24 28 33 / 5%);
  border-radius: 11px;
  height: 300px;
}

.actionButtonsWrapper {
  display: flex;
}

.saveButton {
  margin: 0 15px;
}

.cancelButton {
  @include cancelButton;
}

.title {
  @include pageTitle;
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.wrapper {
  @include pageWrapper;
}

.newForm {
  padding: 30px;
}

.title {
  @include pageTitle;
}

.actionButtonsWrapper {
  display: flex;
}

.saveButton {
  margin: 0 15px;
}

.newForm {
  padding: 30px;
}

.cancelButton {
  @include cancelButton;
}

.header {
  display: flex;
  justify-content: space-between;
  @include generalMargin;
}

.actionsButton {
  @include greenButon;
}

.formTitle {
  @include wrapperTitle;
}

.newItem {
  display: inline;
}

.form {
  padding: 30px;
}

.addNewButton {
  width: 100%;
  margin: 30px 0 0;
}

.inputNumber {
  width: 100%;
}

@media only screen and (max-width: 812px) {
  .actionButtonsWrapper {
    flex-direction: column;
  }

  .cancelButton {
    margin: 5px;
  }

  .saveButton {
    margin: 5px;
  }

  .title {
    font-size: 18px;
  }
}
