@import "../../styles/Globals.module";

.logo {
  width: 100%;
}

.expandedWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  svg {
    width: 20px;
    height: 20px;
  }
}

.collapsedButton {
  width: 100%;
  svg {
    width: 20px;
    height: 20px;
  }
}

.sider {
  background-color: #ffffff;
  border-radius: 0 20px 20px 0;
}

.layoutAntd {
  background-color: $violet-color-1;
}

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-left: 10px;
  background-color: $violet-color-1;
}

:global(.ant-layout-sider-trigger) {
  background-color: #ffffff;
  color: black;
}

:global(.anticon-left) {
  color: #707eae;
}

:global(.anticon-right) {
  color: #707eae;
}
