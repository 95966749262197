@import "../../styles/Mixins.module";
@import "../../styles/Globals.module";

.wrapper {
  @include pageWrapper;
}

.contentWrapper {
  display: flex;
  justify-content: space-around;
  @include md {
    display: flex;
    flex-direction: column;
  }
}
.rolTag {
  @include md {
    margin: 5px;
  }
}

.actionButtonMore {
  @include actionButtonMore;
}

.actionIconMore {
  @include actionIconMore;
}

.mapCategoryWrapper {
  display: flex;
  @include generalMargin;
}

.mapCategoryText {
  margin: 0 5px;
}

.tableCategoriesWrapper {
  height: 100%;
  width: 100%;
}

.filtersWrapper {
  display: flex;
  margin: 10px;
  gap: 4px;

  @include md {
    flex-direction: column;
  }
}

.filterSelect {
  width: 200px;
  @include generalSelectFilters;
}

.filterInput {
  @include inputFilter;
}

.filterWrapper {
  display: flex;
}

.tableCategories {
  padding: 10px;
  @include md {
    width: 100%;
    overflow: auto;
  }
}

.actionButtonsWrapper {
  display: flex;
  @include md {
    flex-direction: column;
  }
}

.newCategoryItem {
  display: inline;
}

.newCategoryButton {
  margin: 0 15px;
  @include greenButon;
}

.importButton {
  @include greenButon;
  margin: 0 5px;
  @include md {
    margin: 5px;
  }
}

.addNewCategoryButton {
  width: 100%;
  @include md {
    margin: 5px;
  }
}

.editButton {
  width: 100%;
  margin-top: 10px;
}

.editItem {
  display: inline;
}

.title {
  @include pageTitle;
}

.header {
  @include generalMargin;
  display: flex;
  justify-content: space-between;
}

.formTitle {
  @include wrapperTitle;
}
